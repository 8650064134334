import { Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Loading from "./components/Loading/Loading.jsx"

// import Header from './components/Header/Header.jsx';
// import Home from './pages/Home.jsx';
// import Leaderboard from "./pages/Leaderboard.jsx";
// import Error from './pages/404.jsx'
import Particle from './components/particles/particles.jsx';

const Header  = lazy(() => import('./components/Header/Header.jsx'))
const Home  = lazy(() => import('./pages/Home.jsx'))
const Leaderboard  = lazy(() => import('./pages/Leaderboard.jsx'))
const PreviousLeaderboard  = lazy(() => import('./pages/PreviousLeaderboard.jsx'))
// const LeaderboardPackdraw  = lazy(() => import('./pages/LeaderboardPackdraw.jsx'))
// const PreviousLeaderboardPackdraw  = lazy(() => import('./pages/PreviousLeaderboardPackdraw.jsx'))
// const Challenges  = lazy(() => import('./pages/Challenges.jsx'))
const Vip  = lazy(() => import('./pages/Vip.jsx'))
// const PreviousChallenges  = lazy(() => import('./pages/PreviousChallenges.jsx'))
const Error  = lazy(() => import('./pages/404.jsx'))

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
          <Header />
        <main>
          <Particle />
          <Routes exact path="/" element={<Header />} >
            <Route exact path="/" element={<Home />} />
            <Route exact path="/leaderboard/stake/active" element={<Leaderboard />} />
            {/* <Route exact path="/leaderboard/stake/previous" element={<PreviousLeaderboard />} /> */}
            {/* <Route exact path="/leaderboard/packdraw/active" element={<LeaderboardPackdraw />} /> */}
            {/* <Route exact path="/leaderboard/packdraw/previous" element={<PreviousLeaderboardPackdraw />} /> */}
            {/* <Route exact path="/challenges" element={<Challenges />} /> */}
            {/* <Route exact path="/previous-challenges" element={<PreviousChallenges />} /> */}
            <Route exact path="/rewards" element={<Vip />} />
            <Route path='*' element={<Error />} />
          </Routes>
        </main>
      </BrowserRouter>
    </Suspense>
  );
}


export default App;
